<template>
  <v-container class="pa-0">
    <div>
      <v-container
        class="reservations-btn-back-container hidden-sm-and-up hidden-md-and-up hidden-sm-and-up hidden-xl-and-up"
      >
        <p class="reservations-btn-back" @click="onBack">
          <v-icon>mdi-chevron-left</v-icon>ย้อนกลับ
        </p>
        <p class="reservations-detail-header">ประวัติคำขอใช้พื้นที่</p>
      </v-container>
    </div>
    <div class="hidden-xs-only" style="margin-top: 53.5px;">
      <p
        class="reservations-btn-back"
        style="width: fit-content; cursor: pointer;"
        @click="onBack"
      >
        <v-icon>mdi-chevron-left</v-icon>ย้อนกลับ
      </p>
      <p class="reservations-detail-header">ประวัติคำขอใช้พื้นที่</p>
    </div>

    <div v-if="bookingDetailItemsSelctedDetail">
      <v-container class="reservations-history-detail-background pt-0">
        <p class="mb-0 reservations-detail-activities">
          {{ bookingDetailItemsSelcted.activities }}
        </p>
        <p
          class="text--regular--13 reservations-detail-bookingNo"
          v-if="bookingDetailItemsSelcted.bookingNo"
        >
          เลขที่การขอใช้พื้นที่ #{{ bookingDetailItemsSelcted.bookingNo }}
        </p>
        <v-row>
          <v-col sm="3" md="3">
            <p class="mb-0 text--bold--16">วันที่ทำรายการ</p>
            <p class="mb-0 text--regular--14">
              {{ bookingDetailItemsSelcted.created_date }}
            </p>
          </v-col>
          <v-col sm="3" md="3">
            <p class="mb-0 text--bold--16">สถานะ</p>
            <span
              class=" text--regular--14"
              v-if="bookingDetailItemsSelcted.status === 'ยังไม่ดำเนินการ'"
              style="color: #BC1C1C;"
              >{{ bookingDetailItemsSelcted.status }}</span
            >
            <span
              class=" text--regular--14"
              v-if="
                bookingDetailItemsSelcted.status === 'รอการแก้ไขพื้นที่' ||
                  bookingDetailItemsSelcted.status ===
                    'อยู่ระหว่างการตรวจสอบ' ||
                  bookingDetailItemsSelcted.status === 'ปรับเปลี่ยนพื้นที่' ||
                  bookingDetailItemsSelcted.status === 'ชำระเงินเรียบร้อย' ||
                  bookingDetailItemsSelcted.status ===
                    'อนุญาต คำขอใช้พื้นที่' ||
                  bookingDetailItemsSelcted.status ===
                    'ไม่อนุญาต คำขอใช้พื้นที่' ||
                  bookingDetailItemsSelcted.status === 'รอการชำระเงิน' ||
                  bookingDetailItemsSelcted.status ===
                    'ยกเลิกโดยผู้ขอใช้พื้นที่'
              "
              style="color: #F4BC00;"
              >{{ bookingDetailItemsSelcted.status }}</span
            >
            <span
              class=" text--regular--14"
              v-if="bookingDetailItemsSelcted.status === 'รอการชำระเงิน'"
              style="color: #E08100"
              >{{ bookingDetailItemsSelcted.status }}</span
            >
            <span
              class=" text--regular--14"
              v-if="bookingDetailItemsSelcted.status === 'สำเร็จ'"
              style="color: #65B52A"
              >{{ bookingDetailItemsSelcted.status }}</span
            >
            <span
              class=" text--regular--14"
              v-if="bookingDetailItemsSelcted.status === 'ยกเลิก'"
              style="color: #DDDDDD"
              >{{ bookingDetailItemsSelcted.status }}</span
            >
          </v-col>
          <v-col
            sm="6"
            md="6"
            class="d-flex justify-sm-start justify-md-end justify-lg-end justify-xl-end"
            v-if="
              bookingDetailItemsSelcted.status !== 'ยกเลิกโดยผู้ขอใช้พื้นที่' &&
                bookingDetailItemsSelcted.status !== 'สำเร็จ' &&
                bookingDetailItemsSelcted.status !== 'ชำระเงินเรียบร้อย' &&
                bookingDetailItemsSelcted.status !==
                  'ไม่อนุญาต คำขอใช้พื้นที่' &&
                bookingDetailItemsSelcted.status !== 'รอการชำระเงิน' &&
                bookingDetailItemsSelcted.status !== 'อนุญาต คำขอใช้พื้นที่'
            "
          >
            <div class="text-center reservations-history-loadmore-btn">
              <v-btn
                rounded
                outlined
                elevation="0"
                class="reservations-history-btn"
                height="45"
                width="229"
                @click="dialogCancel = true"
              >
                <span style="padding: 12px 88px 12px 87px;">ยกเลิกคำขอใช้</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-card class="mx-auto reservations-card" outlined v-if="
                  bookingDetailItemsSelcted.applicantType === 'GUEST' || bookingDetailItemsSelcted.applicantType === 'GOVERNMENTAGENCY' || bookingDetailItemsSelcted.applicantType === 'PRIVATEAGENCY'
                ">
          <v-container
            v-for="item in bookingDetailItemsSelctedDetail"
            :key="item.id"
          >
            <v-row class="mb-3">
              <v-col cols="6" sm="6" md="4" class="text-center">
                <v-container class="pt-2">
                  <img
                    :src="getFileURL(item.room.imageName)"
                    :alt="item.room.imageName"
                  />
                </v-container>
              </v-col>
              <v-col cols="6" sm="6" md="5">
                <p class="mb-0 text--bold--16">{{ item.room.name }}</p>
                <p class="text--regular--14">{{ item.room.description }}</p>
                <p class="mb-0 text--bold--16">วันที่ขอใช้พื้นที่</p>
                <p class="text--regular--14">{{ formatDate(item) }}</p>
                <p class="mb-0 text--bold--16">จำนวน (วัน)</p>
                <p class="text--regular--14">
                  {{ getDateDiff(item) + " วัน (ตามเวลาทำการ)" }}
                </p>
                <p class="mb-0 text--bold--16">ราคาทั้งหมด</p>
                <p class="text--regular--14">
                  {{ item.price.toLocaleString() + " บาท" }}
                </p>
              </v-col>
              <div v-if="item.objective !== null">
                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  class="text-center"
                  v-if="item.objective.indexOf('ใช้เป็นห้องจัดแถลงข่าว') !== -1"
                >
                  <v-row>
                    <v-col
                      class="reservations-detail-text-align"
                      cols="8"
                      offset="4"
                      sm="6"
                      offset-sm="6"
                      md="3"
                      offset-md="0"
                    >
                      <v-btn
                        rounded
                        style="background-color: #FDF7DF !important;"
                        elevation="0"
                        disabled
                      >
                        <span class="primary--text text--bold--14"
                          >ใช้เป็นห้องจัดแถลงข่าว</span
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="
                  bookingDetailItemsSelctedDetail.indexOf(item) !==
                    bookingDetailItemsSelctedDetail.length - 1
                "
              >
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-container>
          <div style="background-color: var(--v-primary); height: 7px;"></div>
          <v-row no-gutters class="sumary-footer-bg sumary-footer-border">
            <v-col
              cols="12"
              sm="8"
              offset-sm="2"
              md="8"
              offset-md="2"
              lg="8"
              offset-lg="2"
            >
              <!-- <v-card class="grey darken-3 lighten-1 white--text"> -->
              <v-card
                class="summary--bottom--container sumary-footer-bg lighten-1 white--text"
                style="box-shadow: none !important;"
              >
                <v-container>
                  <v-row dense>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="6" sm="6" md="6" class="text-start pb-0">
                          <!-- <p class="text--regular--16 mb-0">ค่าประกันสัญญา</p> -->
                          <div
                            class="tooltip primary--text summary-price mb-1"
                            style="cursor: pointer;"
                          >
                            <p
                              class="text--regular--16 mb-0"
                              style="color: #FFFFFF;"
                            >
                              ค่าประกันสัญญา
                              <v-icon class="primary--text"
                                >mdi-help-circle-outline</v-icon
                              >
                            </p>

                            <span class="tooltiptext"
                              >เพิ่ม 10,000 บาทในทุกๆ 7 วัน</span
                            >
                          </div>
                        </v-col>
                        <v-col cols="6" sm="6" md="6" class="text-end pb-0">
                          <p class="summary-price mb-1">
                            {{ bookingDetailItemsSelcted.depositPrice }} บาท
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="pt-0 pb-0">
                        <v-col
                          cols="6"
                          sm="6"
                          md="6"
                          class="text-start pb-0"
                          style="padding-top: 5px;"
                        >
                          <p class="text--regular--16 mb-1">
                            ค่าเงินบำรุงรักษา (อัตราค่าเช่า)
                          </p>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="6"
                          md="6"
                          class="text-end pb-0"
                          style="padding-top: 5px;"
                        >
                          <p class="summary-price mb-1">
                            {{ bookingDetailItemsSelcted.maintenanceCost }} บาท
                          </p>
                        </v-col>
                      </v-row>
                      <v-divider
                        class="grey sumary-divider-padding"
                      ></v-divider>
                      <v-row>
                        <v-col
                          cols="6"
                          sm="6"
                          md="6"
                          class="text-start"
                          style="padding-top: 10px;"
                        >
                          <p class="summary-total">ราคาทั้งหมด</p>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="6"
                          md="6"
                          class="text-end"
                          style="padding-top: 10px;"
                        >
                          <p class="summary-total">
                            {{
                              bookingDetailItemsSelcted.summary.toLocaleString()
                            }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mx-auto reservations-card col-8" outlined v-if="
                  bookingDetailItemsSelcted.applicantType === 'INTERNAL'
                ">
          <v-col cols="12" sm="12" md="12">
            <v-container
              v-if="bookingDetailItemsSelctedDetail !== null && bookingDetailItemsSelctedDetail.length > 0"
              class="applicaion--detail--custom pa-10"
            >
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p class="text--bold--18 government--form--label">
                    รายละเอียดผู้ขอใช้พื้นที่
                  </p>
                </v-col>
              </v-row>
              <v-divider class="mb-2 mt-2"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--14 government--form--label">
                    หน่วยงาน
                  </p>
                  {{bookingDetailItemsSelcted.department_name}}
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--14 government--form--label">
                    กลุ่มงาน
                  </p>
                  {{bookingDetailItemsSelcted.group_name}}
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--14 guestinfo--form--label">
                    เจ้าหน้าที่รับผิดชอบ
                  </p>
                  {{bookingDetailItemsSelcted.thname}}
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--14 formaddress--label">
                    หมายเลขโทรศัพท์ภายใน
                  </p>
                  {{bookingDetailItemsSelcted.tel}}
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <p class="mb-0 text--bold--14 formaddress--label">
                    หมายเลขโทรศัพท์เคลื่อนที่
                  </p>
                  {{bookingDetailItemsSelcted.mobile}}
                </v-col>
              </v-row>
              <v-divider class="mb-2 mt-2"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <p class="mb-0 text--bold--14 government--form--label">
                    ชื่องาน
                  </p>
                  {{bookingDetailItemsSelcted.activities}}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="pre-formatted">
                  <p class="mb-0 text--bold--14 government--form--label">
                    ประเภทของงาน / รายละเอียด
                  </p>
                  {{bookingDetailItemsSelcted.activities_detail}}
                </v-col>
              </v-row>
              <v-divider class="mb-2 mt-2"></v-divider>
              <v-row dense no-gutters>
                <v-container
                  v-for="item in bookingDetailItemsSelctedDetail"
                  :key="item.id"
                >
                  <v-row class="mb-3">
                    <v-col cols="6" sm="6" md="4" class="text-center">
                      <v-container class="pt-2">
                        <img
                          :src="getFileURL(item.room.imageName)"
                          :alt="item.room.imageName"
                        />
                      </v-container>
                    </v-col>
                    <v-col cols="6" sm="6" md="5">
                      <p class="mb-0 text--bold--16">{{ item.room.name }}</p>
                      <p class="text--regular--14">{{ item.room.description }}</p>
                      <p class="mb-0 text--bold--16">วันที่ขอใช้พื้นที่</p>
                      <p class="text--regular--14">{{ formatDate(item) }}</p>
                      <p class="mb-0 text--bold--16">จำนวน (วัน)</p>
                      <p class="text--regular--14">
                        {{ getDateDiff(item) + " วัน (ตามเวลาทำการ)" }}
                      </p>
                    </v-col>
                    <div v-if="item.objective !== null">
                      <v-col
                        cols="12"
                        sm="12"
                        md="3"
                        class="text-center"
                        v-if="item.objective.indexOf('ใช้เป็นห้องจัดแถลงข่าว') !== -1"
                      >
                        <v-row>
                          <v-col
                            class="reservations-detail-text-align"
                            cols="8"
                            offset="4"
                            sm="6"
                            offset-sm="6"
                            md="3"
                            offset-md="0"
                          >
                            <v-btn
                              rounded
                              style="background-color: #FDF7DF !important;"
                              elevation="0"
                              disabled
                            >
                              <span class="primary--text text--bold--14"
                                >ใช้เป็นห้องจัดแถลงข่าว</span
                              >
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="
                        bookingDetailItemsSelctedDetail.indexOf(item) !==
                          bookingDetailItemsSelctedDetail.length - 1
                      "
                    >
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
              <v-divider class="mb-2 mt-2"></v-divider>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <p class="mb-0 text--bold--14 government--form--label">
                    วันที่เริ่มติดตั้ง
                  </p>
                  {{formatDateDisplay(bookingDetailItemsSelcted.setup_start_date)}}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="mb-0 text--bold--14 government--form--label">
                    เวลาเริ่มติดตั้ง
                  </p>
                  {{bookingDetailItemsSelcted.setup_start_time}} น.
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="mb-0 text--bold--14 guestinfo--form--label">
                    เวลาสิ้นสุดติดตั้ง
                  </p>
                  {{bookingDetailItemsSelcted.setup_end_time}} น.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <p class="mb-0 text--bold--14 government--form--label">
                    วันรื้อถอนสมบูรณ์
                  </p>
                  {{formatDateDisplay(bookingDetailItemsSelcted.remove_start_date)}}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="mb-0 text--bold--14 government--form--label">
                    เวลาเริ่มรื้อถอน
                  </p>
                  {{bookingDetailItemsSelcted.remove_start_time}} น.
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="mb-0 text--bold--14 guestinfo--form--label">
                    เวลาสิ้นสุดรื้อถอน
                  </p>
                  {{bookingDetailItemsSelcted.remove_end_time}} น.
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4" v-if="bookingDetailItemsSelctedDetail[0].startDate !== bookingDetailItemsSelctedDetail[0].endDate">
                  <p class="mb-0 text--bold--14 government--form--label">
                    กำหนดการจัดงาน
                  </p>
                  {{formatDateDisplayShort(bookingDetailItemsSelctedDetail[0].startDate)}} - {{formatDateDisplayShort(bookingDetailItemsSelctedDetail[0].endDate)}}
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="bookingDetailItemsSelctedDetail[0].startDate === bookingDetailItemsSelctedDetail[0].endDate">
                  <p class="mb-0 text--bold--14 government--form--label">
                    กำหนดการจัดงาน
                  </p>
                  {{formatDateDisplayShort(bookingDetailItemsSelctedDetail[0].startDate)}}
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="mb-0 text--bold--14 government--form--label">
                    ระยะเวลาจัดงานตั้งแต่
                  </p>
                  เริ่ม {{bookingDetailItemsSelctedDetail.event_start_time}} น. ถึง {{bookingDetailItemsSelctedDetail.event_end_time}} น.
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <p class="mb-0 text--bold--14 guestinfo--form--label">
                    รวมระยะเวลาการจัดงานทั้งหมด
                  </p>
                  {{getDateDiff(bookingDetailItemsSelctedDetail[0])}} วัน
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="pre-formatted">
                  <p class="mb-0 text--bold--14 government--form--label">
                    อุปกรณ์ที่คาดว่าจะต้องใช้
                  </p>
                  {{bookingDetailItemsSelcted.equipment}}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="pre-formatted">
                  <p class="mb-0 text--bold--14 government--form--label">
                    จำนวนที่จอดรถยนต์ (คาดการณ์)
                  </p>
                  {{bookingDetailItemsSelcted.parking}} คัน
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-card>
      </v-container>
    </div>

    <v-dialog v-model="dialogCancel" max-width="702" :retain-focus="false">
      <v-card class="pa-10 confirmation-popup-card">
        <v-card-title class="confirmation-popup-title">
          <p class="mb-0 popup-comfirm-delete-text">
            ยืนยันการยกเลิกคำขอใช้พื้นที่
          </p>
        </v-card-title>
        <v-card-actions class="popup-comfirm-delete">
          <div class="popup-comfirm-delete-container">
            <v-btn
              rounded
              outlined
              elevation="0"
              class="reservations-history-btn"
              width="200"
              @click="onCancel"
            >
              <span style="padding: 12px 88px 12px 87px;">ยกเลิกคำขอใช้</span>
            </v-btn>
            <v-btn
              elevation="0"
              rounded
              class="popup-comfirm-delete-button mb-2"
              color="primary"
              @click="dialogCancel = false"
              width="200"
            >
              <span
                style="color: black; padding: 12px 88px 12px 87px; font-size: 14px;"
                >ไม่ต้องการ</span
              >
            </v-btn>
          </div>
        </v-card-actions>

        <v-btn
          :ripple="false"
          fab
          text
          small
          color="grey darken-2"
          @click="dialogCancel = false"
          class="confirmation--closeicon"
        >
          <v-icon large :ripple="false">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogError" max-width="702" :retain-focus="false">
      <v-card class="pa-10 confirmation-popup-card">
        <v-card-title class="confirmation-popup-title">
          <p class="mb-0 popup-comfirm-delete-text">
            ยกเลิกการขอใช้พื้นที่ ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง
          </p>
        </v-card-title>
        <v-card-actions class="popup-comfirm-delete">
          <div class="popup-comfirm-delete-container">
            <v-btn
              rounded
              outlined
              elevation="0"
              class="reservations-history-btn"
              width="200"
              @click="dialogError = false"
            >
              <span style="padding: 12px 88px 12px 87px;">ตกลง</span>
            </v-btn>
          </div>
        </v-card-actions>

        <v-btn
          :ripple="false"
          fab
          text
          small
          color="grey darken-2"
          @click="dialogError = false"
          class="confirmation--closeicon"
        >
          <v-icon large :ripple="false">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _get from "lodash/get";

export default {
  data: () => ({
    dialogCancel: false,
    dialogError: false
  }),
  computed: {
    ...mapState({
      bookingDetailItemsSelctedDetail(state) {
        if (_get(state.booking, "bookingDetailItemsSelcted.detail", null)) {
          return _get(state.booking, "bookingDetailItemsSelcted.detail", []);
        }
        return null;
      },
      bookingDetailItemsSelcted(state) {
        if (_get(state.booking, "bookingDetailItemsSelcted", null)) {
          return _get(state.booking, "bookingDetailItemsSelcted", []);
        }
        return null;
      }
    })
  },
  created() {
    if (!this.bookingDetailItemsSelctedDetail) {
      this.$router.push("/history");
      this.setBackgroundFormStepperScreen(0); //2021-05-10 : set background
    }
  },
  mounted() {
    this.setBackgroundMain(); //set background : Sasiprapa B. : 2021-05-21
  },
  methods: {
    // Start : Sasiprapa B.: 2021-05-10 : set background
    ...mapActions("app", ["setBackgroundFormStepperScreen"]),
    ...mapActions("sendmail", ["sendEMailUserCancel"]),
    // End : Sasiprapa B.: 2021-05-10  : set background
    onBack() {
      this.$router.push("/history");
      this.setBackgroundFormStepperScreen(0); //2021-05-10 : set background
    },
    formatDate(param) {
      let getStartDate = _get(param, "startDate", "");
      let getEndDate = _get(param, "endDate", "");
      if (getStartDate && getEndDate) {
        let startDate = new Date(getStartDate);
        let endDate = new Date(getEndDate);
        let startDateFormat = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
        let endDateFormat = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
        return `${startDateFormat} - ${endDateFormat}`;
      }
    },
    formatDateDisplay(date) {
      return new Date(date).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
    },
    formatDateDisplayShort(date) {
      return new Date(date).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric"
        });
    },
    getDateDiff(param) {
      let getStartDate = _get(param, "startDate", "");
      let getEndDate = _get(param, "endDate", "");
      const date1 = new Date(getStartDate);
      const date2 = new Date(getEndDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    },
    getFileURL(filename) {
      if (filename) {
        return `${process.env.VUE_APP_URL}/files/${encodeURIComponent(
          filename
        )}`;
      }
      return "";
    },
    // Start : Sasiprapa B.: 2021-04-25
    setBackgroundMain() {
      // set 0 : clear stepper
      this.setBackgroundFormStepperScreen(2);
    },
    async onCancel() {
      this.dialogCancel = false;
      let param = {
        bookingDetailItemsSelcted: this.bookingDetailItemsSelcted,
        bookingDetailItemsSelctedDetail: this.bookingDetailItemsSelctedDetail
      };
      let response = await this.sendEMailUserCancel(param);
      if (_get(response, "data.success", false)) {
        window.location.href = "/history";
        this.setBackgroundMain();
      } else {
        this.dialogError = true;
      }
    }
    // End : Sasiprapa B.: 2021-04-25
  }
};
</script>
<style scoped>
.reservations-detail-header {
  /* Layout Properties */
  margin-bottom: 0px;

  /* UI Properties*/
  font-weight: 700;
  font-size: 24px;
}

.reservations-detail-maintenance-cost {
  font-weight: 700;
  font-size: 18px;
}

.reservations-detail-text-align {
  text-align: end;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  position: absolute;
  z-index: 1;
  top: unset;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Button Back Section */
.reservations-btn-back-container {
  padding-top: 53.5px;
  padding-bottom: 31px;
  background-color: #ffffff;
  height: 131px;
}

.reservations-btn-back {
  /* Layout Properties */
  width: fit-content;
  cursor: pointer;
  margin-bottom: 10px;
  /* UI Properties*/
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #282828;
}

/* Card Section */
.reservations-card {
  border-radius: 10px;
  box-shadow: 0px 0px 15px #00000019;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-top: 46px;
}

/* Footer Section */
.sumary-footer-bg {
  background-color: #282828;
}

.sumary-footer-border {
  border: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 5px 5px;
  /* box-shadow: 0px 0px 15px  !important; */
}

.summary-price {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.summary-total {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
}

.sumary-divider-padding {
  margin-top: 26px;
  margin-bottom: 20px;
}

/* background */
.reservations-history-detail-background {
  background-color: #fafafa !important;
}
.reservations-detail-activities {
  font-weight: 700;
  font-size: 24px;
  margin-top: 43px;
}
.reservations-detail-bookingNo {
  margin-bottom: 25px;
}
/*Mobile */
@media (max-width: 600px) {
  /* Button Back Section */
  .reservations-btn-back {
    /* Layout Properties */
    margin-bottom: 13px;
  }

  .reservations-btn-back-container {
    padding-top: 38px;
    padding-bottom: 29px;
  }

  /* background */
  .reservations-history-detail-background {
    background-color: #f7f7f8 !important;
  }

  .tooltiptext {
    margin-left: 20px !important;
  }
  .reservations-detail-activities {
    margin-top: 0px !important;
    padding-top: 30px !important;
  }
  .reservations-detail-bookingNo {
    margin-bottom: 25px;
  }
  .reservations-card {
    margin-top: 42px;
  }

  .popup-comfirm-delete-container {
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .popup-comfirm-delete-text {
    font-weight: 700;
    font-size: 19px !important;
  }
}

/* Destop */
@media (min-width: 600px) {
  .reservations-btn-back {
    /* Layout Properties */
    margin-bottom: 10px;
  }

  .popup-comfirm-delete-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
  }
}

.popup-comfirm-delete-text {
  font-weight: 700;
  font-size: 21px;
}

.confirmation-popup-card {
  box-shadow: 0px 0px 15px #496c7733 !important;
  border-radius: 10px;
}

.confirmation-popup-title {
  display: flex;
  justify-content: center;
}

.popup-comfirm-delete-button {
  font-weight: 500;
  font-size: 14px;
}

.popup-comfirm-delete {
  display: flex;
  justify-content: center;
}

.confirmation--closeicon {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>
